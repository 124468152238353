import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f6404c14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"position":"relative"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "status-items"
}
const _hoisted_5 = {
  key: 1,
  class: "status-items"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.windowWidth < 820)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ProgressBar, {
            mode: "determinate",
            showValue: false,
            style: {"margin-bottom":"14px","position":"relative"},
            value: 100
          }),
          _createElementVNode("div", {
            class: "status-text",
            style: _normalizeStyle({
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#FFF',
          fontWeight: '500',
          whiteSpace: 'nowrap',
          width: '100%',
          textAlign: 'center',
        })
          }, _toDisplayString(_ctx.progressStatus), 5)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_ProgressBar, {
            mode: "determinate",
            showValue: false,
            style: {"margin-bottom":"14px"},
            value: _ctx.currentProgressValue
          }, null, 8, ["value"]),
          _createElementVNode("div", null, [
            (_ctx.showPreviousStatus)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredStatusData, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "status-text",
                      style: _normalizeStyle({
              color: '#FFF',
              fontWeight: '500',
              whiteSpace: 'nowrap',
              flex: `0 0 calc(100% / ${_ctx.statusBarLength})`,
              textAlign: 'center',
            })
                    }, _toDisplayString(item[0]), 5))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredStatusData, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "status-text",
                      key: index,
                      style: _normalizeStyle({
              color: '#FFF',
              fontWeight: '500',
              whiteSpace: 'nowrap',
              flex: `0 0 calc(100% / ${_ctx.statusBarLength})`,
              textAlign: 'center',
            })
                    }, _toDisplayString(item.includes(_ctx.progressStatus) ? _ctx.progressStatus : ""), 5))
                  }), 128))
                ])),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusBarLength, (item, index) => {
              return (_openBlock(), _createElementBlock("i", {
                key: index,
                class: "pi pi-angle-right",
                style: _normalizeStyle({
            position: 'absolute',
            top: '50%',
            left: `${(index / _ctx.statusBarLength) * 100}%`,
            transform: 'translate(-50%, -50%)',
            color: 'white',
            fontSize: '60px',
            display: index === 0 ? 'none' : 'inline',
          })
              }, null, 4))
            }), 128))
          ])
        ]))
  ]))
}