
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

// Components
import AutoComplete from "primevue/autocomplete";

import SoQuoteService from "@/services/SOQuotesService";
const soQuoteService = new SoQuoteService(process.env.VUE_APP_ABSTRACTION_API);

// Types
interface Data {
  query: string;
  suggestions: string[];
}

export default defineComponent({
  name: "QuoteAutocomplete",
  components: {
    AutoComplete,
  },
  emits: ["item-select"],
  props: {
    selectedId: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data(): Data {
    return {
      query: "",
      suggestions: [],
    };
  },
  watch: {
    selectedId() {
      this.getQuoteById;
    },
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
    }),
    suggestionsIds(): Array<string> {
      return this.suggestions.map((suggestion: any) => suggestion.id);
    },
  },
  methods: {
    handleComplete() {
      soQuoteService.getSOQuotes(this.query).then((response: any) => {
        if (response.soquote_items.length > 0) {
          this.suggestions = response.soquote_items.map((item: any) => item);
        } else {
          this.suggestions = [{ id: "No results found" } as any];
        }
      });
    },
    handleItemSelect(event: any) {
      const selected: any = this.suggestions.find(
        (suggestion: any) => suggestion.id === event.value,
      );
      if (selected.id === "No results found") {
        this.query = "";
        return;
      }
      this.$emit("item-select", selected);
    },
    getQuoteById() {
      if (this.selectedId) {
        soQuoteService.getSOQuotes(this.selectedId).then((response: any) => {
          if (response.soquote_items.length) {
            const quote = response.soquote_items[0];
            this.query = quote.id;
          }
        });
      }
    },
  },
  mounted() {
    this.getQuoteById();
  },
});
