
interface StatusData {
  final: string;
  status: string;
  status_group: string;
}

import { defineComponent } from "vue";

import ProgressBar from "primevue/progressbar";

export default defineComponent({
  name: "StatusDisplayBar",
  components: {
    ProgressBar,
  },
  props: {
    progressStatus: {
      type: String,
      required: true,
    },
    showPreviousStatus: {
      type: Boolean,
      required: false,
      default: false,
    },
    filteredStatusData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    statusBarLength: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    windowWidth: window.innerWidth,
  }),
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    currentProgressValue() {
      const statusListByGroup = this.filteredStatusData as {
        [key: string]: StatusData[];
      };

      let calculatedProgress = 0;
      Object.values(statusListByGroup).map(
        (statusGroup: StatusData[], index: number) => {
          if (statusGroup.some((item: any) => item === this.progressStatus)) {
            calculatedProgress = ((index + 1) / this.statusBarLength) * 100.15;
          }
        },
      );
      return calculatedProgress;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
});
