
interface StatusData {
  final: string;
  status: string;
  status_group: string;
}

import { defineComponent } from "vue";
import SaleOpp, { AttachmentItem, SalesOrderItem } from "@/types/saleOpps";
import { SalesInquiryMap } from "@/types/state/salesInquiry";
import { mapActions, mapGetters, mapState } from "vuex";
import store from "@/store";
import Quote from "@/types/quote";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import TextArea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import Tooltip from "primevue/tooltip";
import Search from "@/components/Search.vue";
import DataTable from "primevue/datatable";
import LogsDataTable from "@/components/UI/LogsDataTable.vue";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import QuoteAutocomplete from "@/components/Autocompletes/QuoteAutocomplete.vue";
import OrderAutocomplete from "@/components/Autocompletes/OrderAutocomplete.vue";
import InputNumber from "primevue/inputnumber";
import Card from "primevue/card";
import ProgressSpinner from "primevue/progressspinner";
import SplitButton from "primevue/splitbutton";
import SpeedDial from "primevue/speeddial";
import CollapsibleSection from "@/components/UI/CollapsibleSection.vue";
import AttachmentRecords from "@/components/Attachments/AttachmentRecords.vue";
import SoQuoteService from "@/services/SOQuotesService";
import SaleOppsService from "@/services/SaleOppsService";
import SalesService from "@/services/SalesService";
import CustomerService from "@/services/CustomerService";
import ProspectService from "@/services/ProspectService";
import Customer from "@/types/customer";
import Prospect from "@/types/prospect";
import { LisItems } from "@/types/salesorder";
import { LiItems } from "@/types/soquote";
import Contact from "@/types/contact";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import SOQuote from "@/types/soquote";
import ContactAutocomplete from "@/components/Autocompletes/Contact.vue";
import EmailFileDialog from "@/components/UI/EmailFileDialog.vue";
import Utils from "@/utility/utils";
import ContactService from "@/services/ContactService";
import PartsService from "@/services/PartsService";
import StatusDisplayBar from "@/components/UI/StatusDisplayBar.vue";

const contactService = new ContactService(process.env.VUE_APP_ABSTRACTION_API);
const customerService = new CustomerService();

export default defineComponent({
  name: "OpportunitiesTab",
  components: {
    Card,
    InputText,
    Button,
    TextArea,
    Dropdown,
    Calendar,
    Search,
    InputNumber,
    AttachmentRecords,
    ContactAutocomplete,
    CollapsibleSection,
    QuoteAutocomplete,
    DataTable,
    Column,
    Dialog,
    OrderAutocomplete,
    ProgressSpinner,
    SplitButton,
    EmailFileDialog,
    SpeedDial,
    LogsDataTable,
    StatusDisplayBar,
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  directives: {
    tooltip: Tooltip,
  },
  validations() {
    return {
      customer: {
        name: {
          required: helpers.withMessage("Please Select a Customer", required),
        },
      },
    };
  },
  data: () => ({
    tabId: "",
    mailType: "",
    mailingOrders: [] as Array<string>,
    mailingQuotes: [] as Array<string>,
    convertingQuotes: [] as Array<string>,
    selectedQuote: {} as SOQuote,
    selectedOrder: {} as any,
    showAddQuote: false,
    showAddOrder: false,
    quotesToShow: [] as Array<SOQuote>,
    ordersToShow: [] as Array<SalesOrderItem>,
    saleOpp: {} as SalesInquiryMap,
    saleOppCopy: {} as SalesInquiryMap,
    soQuote: {} as SOQuote,
    customer: {} as Customer,
    loading: false,
    isLoadingQuotes: false,
    isFormSubmitted: false,
    naValue: "NA",
    hidden: true,
    loadingControls: false,
    prospectName: "",
    prospectPhone: "",
    isLoadingProspect: false,
    isLoadingCustomer: false,
    isLoadingContact: false,
    contactName: "",
    contactPhone: "",
    contactEmail: "",
    contactJobTitle: "",
    soQuoteService: new SoQuoteService(process.env.VUE_APP_ABSTRACTION_API),
    saleOppService: new SaleOppsService(process.env.VUE_APP_ABSTRACTION_API),
    prospectService: new ProspectService(process.env.VUE_APP_ABSTRACTION_API),
    salesService: new SalesService(),
    partService: new PartsService(),
    saleOppAmount: 0.0,
    submitLoading: false,
    loadingAttachments: false,
    isQuotesToShowLoading: false,
    isOrdersToShowLoading: false,
    isLoadingLogEntry: false,
    sectionsStatus: {
      info: true,
      quotesAndSales: true,
      attachments: false,
      logEntries: false,
    },
    status: [
      {
        description: "Closed",
        value: "C",
      },
      {
        description: "New",
        value: "N",
      },
      {
        description: "Backorder",
        value: "B",
      },
      {
        description: "Open",
        value: "O",
      },
      {
        description: "Active",
        value: "A",
      },
      {
        description: "Active",
        value: "",
      },
    ],
    newOrder: {} as any,
    elementIdToEmail: "",
    showEmailDialog: false,
    progressStatus: "",
    statusBarLength: 0,
    statusData: [] as StatusData[],
    filteredStatusData: {} as { [key: string]: string[] },
  }),
  created() {
    if (this.isSaleOpportunityControlEmpty) {
      this.loadingControls = true;
      this.fetchControl({
        procedure: "SALEOPP.CONTROL",
        filename: "CONTROL",
        id: "SALEOPP",
      }).finally(() => {
        this.loadingControls = false;
      });
    }
    this.handleCreatedOrUpdated();

    const groupedData: { [key: string]: string[] } = {};

    for (const item of this.control.saleOpportunity.status_items) {
      const itemTyped = item as StatusData;
      const { status_group } = itemTyped;

      if (!(status_group in groupedData)) {
        groupedData[status_group] = [];
      }

      groupedData[status_group].push((item as StatusData).status);
    }
    this.statusBarLength = Object.keys(groupedData).length;

    this.filteredStatusData = groupedData;
  },
  mounted() {
    const cust_id = this.$attrs.cust_id || "";
    if (cust_id && cust_id != this.saleOpp.cust) {
      this.saleOpp.cust = cust_id as any;
      this.initCust();
    }
  },
  watch: {
    "$attrs.tabs.id": {
      handler: function (newVal: string, oldVal: string) {
        if (newVal !== oldVal) {
          this.handleCreatedOrUpdated();
        }
      },
      deep: true,
    },
    "saleOpp.status": function (newVal) {
      this.progressStatus = newVal;
    },
  },

  computed: {
    ...mapState(["control", "notification"]),
    ...mapGetters({
      isSaleOpportunityControlEmpty: "control/isSaleOpportunityControlEmpty",
      getUserId: "session/getUserId",
      getFolderNames: "control/getFolderNames",
      getCustomer: "customerInquiry/getCustomer",
      getClient: "session/getClient",
      getActiveOrderTab: "salesInquiry/getActiveOrderTab",
      getLoadingQuotes: "soQuotes/getLoadingQuotes",
      getQuotes: "soQuotes/getQuote",
      getPDFs: "sales/getPDFS",
      getLoadingPDFs: "sales/getLoadingPDFs",
    }),
    getSplitButtonItems() {
      return [
        {
          label: "Link Existing Quote",
          icon: "pi pi pi-plus",
          class: "text-sm",
          iconPos: "left",
          command: () => {
            this.toggleAddItemDialog(true, "quotes");
          },
        },
      ];
    },
    header(): string {
      return this.isReadOnly
        ? `Opportunity #${this.$attrs.oppId}`
        : "New Sales Opportunity";
    },
    associatedTypes(): Array<any> {
      return [
        { assoc_file: "Saleopp", assoc_id: this.saleOpp.id },
        { assoc_file: "Contact", assoc_id: this.saleOpp.contact },
      ];
    },
    isReadOnly(): boolean {
      return !this.saleOpp.id.startsWith("New") && this.saleOpp.id != "";
    },
    isCustomerModule(): boolean {
      return this.$route.fullPath.includes("customers");
    },
    customerContacts(): Array<any> {
      if (this.customer && this.customer.contact_id_items) {
        return this.customer.contact_id_items.map((contact: any) => {
          var names = [] as string[];
          if (contact.contact_name)
            [(names = [...contact.contact_name.split(" "), "", ""])];
          names = [...names, "", ""];
          return {
            contact_id: contact.contact_id,
            first_name: names[0],
            last_name: names[1],
          };
        });
      } else {
        return [];
      }
    },
  },

  methods: {
    ...mapActions({
      fetchControl: "control/fetchControl",
      addNotification: "notification/add",
      fetchAttachments: "attachments/fetchAttachments",
      downloadAttachment: "attachments/downloadAttachment",
      updateOpenedSalesInquiry: "salesInquiry/updateOpenedSalesInquiry",
      addOpenedSalesOrder: "salesInquiry/addOpenedSalesInquiry",
      getQuotePDF: "soQuotes/getQuotePDF",
      getOrderPDF: "sales/getOrderPDF",
      removeOpenedSalesInquiry: "salesInquiry/removeOpenedSalesInquiry",
    }),
    inputStyle(input: string) {
      return {
        color: "#66e0ff",
        display: "flex",
        alignSelf: "center",
        justifyContent: "left",
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
        borderWidth: "1px",
        borderColor: "#ced4da",
        borderStyle: "solid",
        width: "100%",
        padding: input ? "8px" : "18px",
        boxSizing: "border-box",
      };
    },
    exitSalesOpps() {
      this.removeOpenedSalesInquiry(this.saleOpp);
      this.$router.push("/customers/sales/opportunities");
    },
    handleCreatedOrUpdated() {
      this.tabId = (this.$attrs.tab as SalesInquiryMap)?.id ?? "";
      this.initSalesOpp();
      const activeTab = this.getActiveOrderTab(
        (this.$attrs.tab as SalesInquiryMap)?.id,
      );

      this.saleOpp = {
        ...this.saleOpp,
        ...activeTab,
      };

      if (!this.tabId.startsWith("New") && this.tabId !== "") {
        this.saleOppCopy = JSON.parse(JSON.stringify(this.saleOpp));
        this.saleOppAmount = parseFloat(this.saleOpp.amount);
        this.getControls();
        this.initQuotesToShow();
        this.initOrdersToShow();
      }

      this.initCust();
      this.initProspect();
      this.initContact();

      if (!this.saleOpp.date && this.saleOpp.id.startsWith("New")) {
        this.saleOpp.date = Utils.formatDate(new Date().toLocaleDateString());
      }
      if (this.saleOpp.date === "") {
        this.saleOpp.date = Utils.formatDate(new Date().toLocaleDateString());
      }
      if (!this.saleOpp.assigned_to && this.saleOpp.id.startsWith("New")) {
        this.saleOpp.assigned_to = this.getUserId;
      }
    },
    getEmailDialogTitle(mailType: string, id: string) {
      return mailType === "order"
        ? `Email Sales Order ${id}`
        : `Email Quote ${id}`;
    },
    handleSendEmail(data: any, mailType: string) {
      if (mailType === "order") {
        this.sendOrderEmail(data);
      } else if (mailType === "quote") {
        this.sendQuoteEmail(data);
      }
    },
    sendQuoteEmail(data: any) {
      let email2 = "";
      data.To.forEach((mailTo: string) => {
        email2 = email2 + " " + mailTo;
      });
      this.mailingQuotes.push(this.elementIdToEmail);
      this.soQuoteService
        .SOQuotePdf(this.elementIdToEmail, email2, "Y", "Y")
        .then((response: any) => {
          if (response === "success") {
            this.addNotification({
              message: `Sales Quote ${this.elementIdToEmail} has been emailed successfully`,
              type: "success",
            });
          } else {
            this.addNotification({
              message: `Sales Quote was not sent`,
              type: "error",
            });
          }
        })
        .catch((error: any) => {
          this.addNotification({
            message: `Sales Quote could not be sent: ${error}`,
            type: "error",
          });
        })
        .finally(() => {
          this.removeLoadingElement(this.elementIdToEmail, "mailingQuotes");
          this.mailType = "";
          this.elementIdToEmail = "";
        });
    },
    sendOrderEmail(data: any) {
      this.mailingOrders.push(this.elementIdToEmail);
      this.salesService
        .getOrderPDF(this.elementIdToEmail, this.getClient, data)
        .then((response) => {
          if (response === "success") {
            this.addNotification({
              message: `Sales Order #${this.elementIdToEmail} has been emailed successfully`,
              type: "success",
            });
          } else {
            this.addNotification({
              message: `Sales Order email was not sent`,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.addNotification({
            message: `Sales Order email could not be sent: ${error}`,
            type: "error",
          });
        })
        .finally(() => {
          this.removeLoadingElement(this.elementIdToEmail, "mailingOrders");
          this.mailType = "";
          this.elementIdToEmail = "";
        });
    },
    handleEmailPDF(id: string, emailType: string) {
      this.mailType = emailType;
      this.elementIdToEmail = id;
      this.showEmailDialog = true;
    },
    downloadQuotePDF(id: string) {
      this.getQuotePDF({ recordId: id });
    },
    getSalesOrderPDF(id: string) {
      this.getOrderPDF({ client: this.getClient, recordId: id });
    },
    handlePDFLoading(id: string, elementType: string) {
      let downloading = false;
      if (elementType === "order") {
        downloading =
          this.getLoadingPDFs.find((i: string) => i === id) !== undefined;
        return downloading;
      } else if (elementType === "quote") {
        downloading = this.getLoadingQuotes?.includes(id) || false;
        return downloading;
      }
    },
    handlePDFIcons(id: string, elementType: string) {
      let downloaded: any = [];
      let downloading = false;
      if (elementType === "order") {
        downloaded = this.getPDFs;
        downloading =
          this.getLoadingPDFs.find((i: string) => i === id) !== undefined;
      } else if (elementType === "quote") {
        downloaded = this.getQuotes;
        downloading = this.getLoadingQuotes?.includes(id) || false;
      }
      return {
        "pi pi-download":
          downloaded.find((i: any) => i.id === id) === undefined &&
          !downloading,
        "pi pi-spin pi-spinner": downloading,
        "pi pi-file-pdf":
          downloaded.find((i: any) => i.id === id) !== undefined &&
          !downloading,
      };
    },
    convertQuoteToOrder(data: any) {
      this.convertingQuotes.push(data.id);
      this.soQuoteService
        .getSOQuotes(data.id)
        .then(async (response: any) => {
          if ((response as any).soquote_items?.length === 1) {
            const SOQuoteRecord = (response as any).soquote_items[0] as SOQuote;

            this.newOrder.contact_email = SOQuoteRecord.email || "";
            this.newOrder.phone = SOQuoteRecord.phone || "";
            this.newOrder.co_code = SOQuoteRecord.co_code || "";
            this.newOrder.terms_code = SOQuoteRecord.terms || "";
            this.newOrder.cust_code = SOQuoteRecord.code || "";
            this.newOrder.sold_to = SOQuoteRecord.customer || "";
            this.newOrder.cust_id = SOQuoteRecord.customer || "";
            this.newOrder.cust_name = SOQuoteRecord.name || "";
            this.newOrder.date = Utils.formatDate(new Date()).replaceAll(
              "-",
              "/",
            );

            this.newOrder.lis_items = SOQuoteRecord.li_items || [];

            if (this.newOrder.contact_id !== SOQuoteRecord.contact) {
              this.getContact(SOQuoteRecord.contact);
            }

            const reformattedPartInfo = [] as Array<LisItems>;
            const promises = [] as Array<Promise<any>>;
            this.newOrder.lis_items.forEach((qtyObject: LiItems) => {
              const data = {
                number: qtyObject.part,
                quantity: qtyObject.qty_items[0].qty as number,
                date: Utils.formatDate(new Date()),
              };

              const qty = data.quantity.toString() ?? "1";
              const promise = this.partService
                .getPriceByPartId(
                  data.number,
                  store.getters["session/getClient"],
                  qty,
                  this.newOrder.cust_code,
                  this.newOrder.sold_to,
                  data.date,
                )
                .then((response: any) => {
                  reformattedPartInfo.push({
                    lis: (reformattedPartInfo.length + 1).toString(),
                    li_parts: data.number,
                    li_order_qtys: qty,
                    li_prices: response.price || "",
                    li_sched_dates_items: [
                      {
                        li_sched_dates: data.date,
                        li_sched_qtys: qty,
                      },
                    ],
                    wrap_desc: response.wrap_desc?.replaceAll("^", " "),
                  } as LisItems);
                });

              promises.push(promise);
            });
            await Promise.all(promises).then(() => {
              this.newOrder.lis_items = [...reformattedPartInfo];
              this.saveOrder({ ...this.newOrder });
            });
          } else {
            const notification = {
              type: "error",
              message: `Error converting Quote #${data.id} to order.`,
            };
            this.addNotification(notification);
          }
        })
        .catch(() => {
          const notification = {
            type: "error",
            message: `Error converting Quote #${data.id} to order.`,
          };
          this.addNotification(notification);
        })
        .finally(() => {
          this.removeLoadingElement(data.id, "convertingQuotes");
        });
    },
    async saveOrder(data: any) {
      this.salesService
        .postOrder({ ...data })
        .then((response: any) => {
          let saleId = response.recordId;

          this.newOrder.so_id = saleId;
          this.ordersToShow.push({
            so_id: this.newOrder.so_id,
            date: this.newOrder.date || this.newOrder.book_date || "",
            customer: this.newOrder.cust_name || "",
          } as any);

          if (
            this.saleOpp.so_items == null ||
            this.saleOpp.so_items == undefined
          ) {
            this.saleOpp.so_items = [];
          }
          this.saleOpp.so_items.push({ so: saleId } as any);

          this.save();

          const notification = {
            message: `Successfully Created Order #${saleId}.`,
            type: "success",
          };
          this.addNotification(notification);
        })
        .catch((error) => {
          this.submitLoading = false;
          this.isFormSubmitted = false;
          const notification = {
            message: error.message,
            type: "error",
          };
          store.dispatch("notification/add", notification);
        })
        .finally(() => {
          this.newOrder = {} as any;
        });
    },
    getContact(contact_id: string) {
      contactService
        .getContacts(contact_id, store.getters["session/getClient"])
        .then((response: any) => {
          if (response.length > 0) {
            const contact = (response as Array<Contact>)[0];
            if (contact.contact_id != this.newOrder.contact_id) {
              this.newOrder.contact_id = contact.contact_id;
            }
            this.newOrder.contact =
              contact.first_name + " " + contact.last_name;

            if (
              contact.telephone_items != null ||
              contact.telephone_items != undefined
            ) {
              this.newOrder.phone = contact.telephone_items[0].telephone;
            }

            if (
              contact.email_address_items != null ||
              contact.email_address_items != undefined
            ) {
              this.newOrder.contact_email =
                contact.email_address_items[0].email_address || "";
            }
          }
        });
    },
    getStatusDescription(status: string) {
      const statusObj = this.status.find((s) => s.value === status);
      return statusObj ? statusObj.description : "";
    },
    onReorderItems(event: any, targetList: "quotes" | "orders") {
      if (targetList === "quotes") {
        this.quotesToShow = [...event.value];
        this.saleOpp.quote_items = event.value.map((quote: any) => ({
          quote: quote.id,
        }));
      } else if (targetList === "orders") {
        this.ordersToShow = [...event.value];
        this.saleOpp.so_items = event.value.map((quote: any) => ({
          so: quote.so_id,
        }));
      }
    },
    addElementToTable(targetTable: "quotes" | "orders") {
      const selected =
        targetTable === "quotes" ? "selectedQuote" : "selectedOrder";
      const targetList =
        targetTable === "quotes" ? "quotesToShow" : "ordersToShow";
      const saleOppTargetList =
        targetTable === "quotes" ? "quote_items" : "so_items";

      let exists: any[] = [];
      if (targetTable === "quotes") {
        exists = this.saleOpp.quote_items.filter(
          (quote: any) => quote.quote === this[selected].id,
        );
      } else {
        exists = this.saleOpp.so_items.filter(
          (so: any) => so.so === this[selected].so_id,
        );
      }
      if (exists.length > 0) {
        this.toggleAddItemDialog(false, targetTable);
        return;
      }

      if (this[selected] && (this[selected].id || this[selected].so_id)) {
        const data =
          targetTable === "quotes"
            ? { quote: this[selected].id }
            : { so: this[selected].so_id };
        this.saleOpp[saleOppTargetList].push(data as any);
        const element =
          targetTable === "quotes"
            ? { id: this[selected].id }
            : { so_id: this[selected].so_id };
        const dataToPush: any = {
          ...element,
          date:
            this[selected].date ||
            this[selected].valid_thru ||
            this[selected].book_date ||
            "",
          customer:
            this[selected].name ||
            this[selected].customer ||
            this[selected].cust_name ||
            "",
        };
        if (targetTable === "quotes") {
          dataToPush["status"] = this[selected].status as string;
        }
        this[targetList].push(dataToPush as any);
        this.toggleAddItemDialog(false, targetTable);
        this[selected] = {} as any;
      } else {
        this.toggleAddItemDialog(false, targetTable);
      }
    },
    toggleAddItemDialog(status: boolean, target: "quotes" | "orders") {
      if (target === "quotes") {
        this.showAddQuote = status;
      } else if (target === "orders") {
        this.showAddOrder = status;
      }
    },
    handleElementSelected(event: any, target: "quotes" | "orders") {
      if (target === "quotes") {
        this.selectedQuote = { ...event };
      } else if (target === "orders") {
        this.selectedOrder = { ...event };
      }
    },
    async initQuotesToShow() {
      this.isQuotesToShowLoading = true;
      if (this.saleOpp.quote_items && this.saleOpp.quote_items.length > 0) {
        const promises = this.saleOpp.quote_items.map(async (quote: any) => {
          const response: any = await this.soQuoteService.getSOQuotes(
            quote.quote,
          );
          if (response.soquote_items.length > 0) {
            return {
              id: response.soquote_items[0].id,
              date:
                response.soquote_items[0].date ||
                response.soquote_items[0].valid_thru ||
                "",
              customer:
                response.soquote_items[0].name ||
                response.soquote_items[0].customer ||
                "",
              status: response.soquote_items[0].status,
            };
          }
          return null;
        });
        const results = await Promise.all(promises);
        this.quotesToShow = results.filter(
          (result) => result !== null,
        ) as Array<SOQuote>;
      } else {
        this.quotesToShow = [];
        this.saleOpp["quote_items"] = [];
      }
      this.isQuotesToShowLoading = false;
    },
    async initOrdersToShow() {
      this.isOrdersToShowLoading = true;
      if (this.saleOpp.so_items && this.saleOpp.so_items.length > 0) {
        const promises = this.saleOpp.so_items.map(async (so: any) => {
          const response: any = await this.salesService.getOrderById(
            so.so,
            "cust_name",
          );
          if (response) {
            return {
              so_id: response.so_id,
              date: response.date || response.book_date || "",
              customer: response.cust_name || "",
            };
          }
          return null;
        });
        const results = await Promise.all(promises);
        this.ordersToShow = results.filter((result) => result !== null) as any;
      } else {
        this.ordersToShow = [];
        this.saleOpp["so_items"] = [];
      }
      this.isOrdersToShowLoading = false;
    },
    handleDeleted(event: any) {
      if (event.success)
        this.fetchOpportunityAttachments(this.saleOpp, "RE-FETCH");
    },
    handleSaved(event: any) {
      if (event.success)
        this.fetchOpportunityAttachments(this.saleOpp, "RE-FETCH");
    },
    fetchOpportunityAttachments(object: SaleOpp, status: string) {
      switch (status) {
        case "FETCH":
          {
            this.loadingAttachments = true;
            this.fetchAttachments({
              record: object,
            })
              .catch((error) => {
                this.addNotification({
                  message: `Failed to load attachments: ${error}`,
                  type: "error",
                });
              })
              .finally(() => {
                this.loadingAttachments = false;
              });
          }
          break;
        case "RE-FETCH":
          // fetch updated SOQuote
          this.saleOppService
            .getSaleOpps(
              object.id,
              1,
              100,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "cust_name",
            )
            .then((response: any) => {
              const attachments =
                response.saleopp_items[0].attachments_items ??
                ([] as Array<AttachmentItem>);
              let data = JSON.parse(JSON.stringify(this.saleOpp));
              data = {
                ...data,
                attachments_items: [...attachments],
                oldRecord: {
                  ...JSON.parse(JSON.stringify(this.saleOpp)),
                  attachments_items: [...attachments],
                },
              };
              this.updateOpenedSalesInquiry(data);

              this.saleOppCopy.attachments_items = [...attachments];
              this.saleOpp = JSON.parse(
                JSON.stringify({
                  ...this.saleOpp,
                  attachments_items: [...attachments],
                }),
              );
              (this.saleOpp.oldRecord as any).attachments_items = [
                ...attachments,
              ];

              this.loadingAttachments = true;
              this.fetchAttachments({
                record: this.saleOpp,
              })
                .catch((error) => {
                  this.addNotification({
                    message: `Failed to load attachments for opportunity: ${error}`,
                    type: "error",
                  });
                })
                .finally(() => {
                  this.loadingAttachments = false;
                });
            })
            .catch((error) => {
              this.addNotification({
                message: `Failed to load attachment ID ${this.saleOpp.id}: ${error}`,
                type: "error",
              });
            });
          break;
        default:
          break;
      }
    },
    async createQuote() {
      this.isFormSubmitted = true;
      // ensure that the sale opp fields are filled correctly
      const isCustomerFieldPopulated = await (
        this.v$.customer as any
      ).name.$validate();
      if (isCustomerFieldPopulated) {
        // MAP QUOTE TO SALE OPP
        this.soQuote.id = "";
        this.soQuote.date = Utils.formatDate(new Date());
        this.soQuote.prospect = this.saleOpp.prospect || "";
        this.soQuote.customer = this.saleOpp.cust;
        this.soQuote.name = this.saleOpp.cust_name || "";
        this.soQuote.quoted_by = this.getUserId;
        this.soQuote.status = "N";
        this.soQuote.contact_id = this.saleOpp.contact || "";
        this.soQuote.contact = this.saleOpp.contact || "";

        this.isFormSubmitted = true;
        this.isLoadingQuotes = true;

        this.soQuoteService
          .postSOQuote(this.soQuote)
          .then((response) => {
            let soQuoteId = (response as any).recordId;

            this.addNotification({
              message: `Created Quote #${soQuoteId}`,
              type: "success",
            });

            this.soQuote.id = soQuoteId;
            this.selectedQuote = { ...this.soQuote };
            this.addElementToTable("quotes");

            this.addOpenedSalesOrder({
              ...this.soQuote,
              saleType: "quotes",
              oldRecord: JSON.parse(JSON.stringify(this.soQuote)),
            });
            this.$router.push(`/sales/quotes/${soQuoteId}`);
          })
          .catch(() => {
            this.addNotification({
              message: `Error creating Quote.`,
              type: "error",
            });
          })
          .finally(() => {
            this.submitLoading = false;
            this.isFormSubmitted = false;
            this.isLoadingQuotes = false;
          });
      }
    },
    getControls() {
      if (this.isSaleOpportunityControlEmpty) {
        this.loadingControls = true;
        this.fetchControl({
          procedure: "SALEOPP.CONTROL",
          filename: "CONTROL",
          id: "SALEOPP",
          control: "SaleOpp",
        }).finally(() => {
          this.loadingControls = false;
        });
      }
    },
    initSalesOpp() {
      this.saleOpp = {
        id: this.$attrs.tab != null ? (this.$attrs.tab as any).id : "",
        contact: "",
        amount: "",
        type: "",
        source: "",
        est_close: "",
        date: Utils.formatDate(new Date()),
        priority: "",
        assigned_to: this.getUserId,
        follow_up_date: "",
        status: "",
        notes: "",
        quote_items: [] as Array<Quote>,
        so_items: [] as Array<SalesOrderItem>,
        prospect: "",
        cust: "",
        attachments_items: [] as Array<AttachmentItem>,
      } as SalesInquiryMap;
      this.saleOppAmount = 0.0;
      this.customer = {} as Customer;
      this.prospectName = "";
      this.prospectPhone = "";
      this.isFormSubmitted = false;
    },
    initSoQuote() {
      this.soQuote = {
        id: "",
        date: "",
        prospect: "",
        customer: "",
        name: "",
        contact: "",
        quoted_by: this.getUserId,
        notes: "",
        contact_id: "",
      } as SOQuote;
    },
    formatDate(
      date: Date,
      targetDate: "est_close" | "date" | "follow_up_date" = "est_close",
    ) {
      const formattedString = Utils.formatDate(date);
      (this.saleOpp as any)[targetDate] = formattedString;
    },
    formatNumber(number: number) {
      return number.toString();
    },
    setCustomer(customer: Customer) {
      this.saleOpp.cust = customer.cust_id as string;
      this.saleOpp.cust_name = customer.name as string;
      this.initCust();
    },
    async save() {
      this.isFormSubmitted = true;

      const isCustomerFieldPopulated = await (
        this.v$.customer as any
      ).name.$validate();
      if (isCustomerFieldPopulated) {
        this.saleOpp.amount = this.saleOpp.amount
          ? this.saleOpp.amount.toString()
          : "0.0";
        this.saleOpp.recurring_amt = this.saleOpp.recurring_amt
          ? this.saleOpp.recurring_amt.toString()
          : "0.0";
        this.saleOpp.prob_pct = this.saleOpp.prob_pct
          ? this.saleOpp.prob_pct.toString()
          : "0.0";
        this.saleOpp.date = this.saleOpp.date === null ? "" : this.saleOpp.date;
        this.submitLoading = true;

        const oldData = JSON.parse(
          JSON.stringify(this.saleOpp.oldRecord ?? {}),
        );
        delete oldData.saleType;

        const data = JSON.parse(JSON.stringify(this.saleOpp));
        delete data.saleType;
        delete data.oldRecord;

        if (!this.hasOppChanged(data, oldData)) {
          this.submitLoading = false;
          this.addNotification({
            message: "No changes detected",
            type: "success",
          });
          return;
        }

        if (this.isReadOnly) {
          delete data.oldId;
          // the dialog is called on row click - editing a record
          this.saleOppService
            .updateSaleOpp(data, oldData)
            .then((resp: any) => {
              this.submitLoading = false;

              // refetch opp
              this.saleOpp.id = resp.recordId;
              delete (this.saleOpp as any).oldRecord;
              const oldRecord = JSON.parse(JSON.stringify(this.saleOpp));
              this.saleOpp.oldRecord = JSON.parse(JSON.stringify(oldRecord));
              this.saleOppCopy = JSON.parse(JSON.stringify(this.saleOpp));

              const notification = {
                message: `Sale Opportunity #${resp.recordId} updated.`,
                type: "success",
              };
              this.addNotification(notification);

              if (this.isCustomerModule) {
                this.removeOpenedSalesInquiry(this.saleOpp);
                this.$router.push(`/customers/sales/opportunities/`);
              } else {
                this.$router.push("/sales/opportunities");
              }
            })
            .catch((error: any) => {
              this.submitLoading = false;
              const notification = {
                message: "Sale Opportunity update failed" + error.toString(),
                type: "error",
              };
              this.addNotification(notification);
            });
        } else {
          delete data.id;
          this.saleOppService
            .postSaleOpp(data)
            .then((response: any) => {
              this.submitLoading = false;
              this.saleOpp.id = response.recordId;
              delete (this.saleOpp as any).oldRecord;
              const oldRecord = JSON.parse(JSON.stringify(this.saleOpp));
              this.saleOpp.oldRecord = JSON.parse(JSON.stringify(oldRecord));
              this.saleOppCopy = JSON.parse(JSON.stringify(this.saleOpp));
              this.$router.push(`/sales/opportunities/${response.recordId}`);

              const notification = {
                message: `Sale Opportunity #${response.recordId} created.`,
                type: "success",
              };
              this.addNotification(notification);

              if (this.isCustomerModule) {
                this.removeOpenedSalesInquiry(this.saleOpp);
                this.$router.push(`/customers/sales/opportunities/`);
              } else {
                this.$router.push("/sales/opportunities");
              }

              this.initQuotesToShow();
              this.initOrdersToShow();
            })
            .catch(() => {
              this.submitLoading = false;
              const notification = {
                message: "Error saving Sale Opportunity.",
                type: "error",
              };
              this.addNotification(notification);
            });
        }
        this.isFormSubmitted = false;
      }
    },
    hasOppChanged(data: any, oldData: any) {
      const ignoreFields = ["saleType"];
      if (data.notes === undefined) {
        ignoreFields.push("notes");
      }
      return Utils.compareTwoObjects(data, oldData, ignoreFields);
    },
    setProspect(prospect: Prospect) {
      this.saleOpp.prospect = prospect.id;
      this.prospectName = "";
      this.prospectPhone = "";
      this.initProspect();
    },
    initContact() {
      if (this.saleOpp.contact) {
        this.isLoadingContact = true;
        contactService
          .searchContacts(this.saleOpp.contact, this.getClient)
          .then((response: any) => {
            if (response.contact_items.length) {
              const contact = response.contact_items[0];
              this.handleContactSelected(contact);
            }
          })
          .finally(() => {
            this.isLoadingContact = false;
          });
      }
    },
    initCust() {
      if (this.saleOpp.cust) {
        this.isLoadingCustomer = true;
        customerService
          .getCustomer(this.saleOpp.cust, this.getClient, "contact_name")
          .then((response) => {
            this.customer = response as Customer;
          })
          .finally(() => {
            this.isLoadingCustomer = false;
          });
      }
    },
    initProspect() {
      if (this.saleOpp.prospect) {
        this.isLoadingProspect = true;
        this.prospectService
          .getProspect(this.saleOpp.prospect)
          .then((response) => {
            this.prospectName = (response as Prospect).name as string;
            this.prospectPhone = (response as Prospect).phone || "";
          })
          .finally(() => {
            this.isLoadingProspect = false;
          });
      }
    },
    handleContactSelected(contact: any) {
      this.saleOpp.contact = contact.contact_id;
      this.contactName = "";
      this.contactPhone = "";
      this.contactEmail = "";
      this.contactJobTitle = "";

      if (this.saleOpp.contact) {
        this.isLoadingContact = true;
        contactService
          .getContacts(this.saleOpp.contact, this.getClient)
          .then((response: any) => {
            if (response.contact_items.length > 0) {
              const contact = response.contact_items[0];
              this.contactName = `${contact.first_name} ${
                contact.last_name ? contact.last_name : ""
              }`;
              this.contactPhone =
                contact.telephone_items && contact.telephone_items.length > 0
                  ? contact.telephone_items[0].telephone
                  : "";
              this.contactEmail =
                contact.email_address_items &&
                contact.email_address_items.length > 0
                  ? contact.email_address_items[0].email_address
                  : "";
              this.contactJobTitle = contact.job_title || "";
            }
          })
          .finally(() => {
            this.isLoadingContact = false;
          });
      }
    },
    toggleSectionIcon(
      tab: "info" | "quotesAndSales" | "attachments" | "logEntries",
    ) {
      this.sectionsStatus[tab] = !this.sectionsStatus[tab];
    },
    deleteTableElement(index: number, target: "quotes" | "orders") {
      if (target === "quotes") {
        this.quotesToShow.splice(index, 1);
        this.saleOpp.quote_items.splice(index, 1);
      } else if (target === "orders") {
        this.ordersToShow.splice(index, 1);
        this.saleOpp.so_items.splice(index, 1);
      }
    },
    async openTableElement(index: number, target: "quotes" | "orders") {
      let data = {} as any;
      if (target === "quotes") {
        const quoteId = this.quotesToShow[index].id;
        const response: any = await this.soQuoteService.getSOQuotes(quoteId);
        if (response.soquote_items.length > 0) {
          data = response.soquote_items[0];
        }
      }
      if (target === "orders") {
        const soId = (this.ordersToShow[index] as any).so_id;
        const response: any = await this.salesService.getOrderById(
          soId,
          "cust_name",
        );
        if (response) {
          data = response;
        }
      }

      data["saleType"] = target;
      data["oldRecord"] = JSON.parse(JSON.stringify(data));

      this.addOpenedSalesOrder({ ...data });
      this.$router.push(`/sales/${target}/${data.so_id || data.id}`);
    },

    isIconLoading(
      id: string,
      targetList: "mailingQuotes" | "mailingOrders" | "convertingQuotes",
    ) {
      return this[targetList].includes(id);
    },
    removeLoadingElement(
      id: string,
      targetList: "mailingQuotes" | "mailingOrders" | "convertingQuotes",
    ) {
      this[targetList] = this[targetList].filter(
        (elementId) => elementId !== id,
      );
    },
  },
});
